.contacts {
    padding-top: 43px;
    font-family: var(--font-helvetica);
}

.contacts__title {
    margin-bottom: 15px;
}

.contacts__wrapper {
    border-top: 1px solid var(--color-darker);
    padding-top: 22px;
    padding-bottom: 50px;
}

.contacts__shortlist {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.shortitem {
    display: flex;
    gap: 9px;

    .shortitem__icon,
    .shortitem__icon svg {
        transition: all 0.18s ease;
    }

    &:hover {
        .shortitem__icon {
            background: var(--color-darker);

            svg path {
                fill: #ffffff;
            }
        }
    }
}

.contacts__full-list__main-row li {
    a[href^='mailto:']:hover {
        text-decoration: none;
    }

    a[href^='tel:']:hover {
        transition: all var(--trans);
        text-decoration: underline;
        color: var(--color-blue);
    }
}

.shortitem span,
.shortitem a {
    padding-top: 5px;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
}

.shortitem__icon {
    height: 30px;
    flex: 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-darker);
    border-radius: 50%;
}

.shortitem__icon svg {
    width: 50%;
    height: 50%;
}

.contacts__map {
    position: relative;
    width: 100vw;
    height: 320px;
    margin: 19px -16px;
    filter: grayscale(1) !important;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.contacts__full-list__main-row {
    display: flex;
    flex-direction: column;
    gap: 19px;

    li {
        color: #333333;

        span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;
            margin-bottom: 10px;
        }

        a {
            display: block;
            color: #333333;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.28px;
        }

        a[href^='mailto:'] {
            text-decoration-line: underline;
        }
    }
}

.contacts__full-list__sub-row {
    margin-top: 18px;

    .footer__row {
        justify-content: flex-start;
        gap: 7px;
        margin-bottom: 25px;
    }
}

@media (min-width: 768px) {
    .contacts__map {
        margin: 19px -26px;
    }
}

@media (min-width: 1024px) {
    .contacts__wrapper {
        padding-top: 55px;
        padding-bottom: 0;
    }

    .contacts__container {
        display: flex;
        flex-direction: column;
    }

    .contacts__shortlist {
        order: 1;
        margin-left: 30%;
        padding-bottom: 38px;
        gap: 20px;
        border-bottom: 1px solid var(--color-darker);
    }

    .contacts__full-list {
        order: 2;
        margin-left: 30%;
        padding-top: 25px;
        margin-bottom: 50px;
    }

    .contacts__map {
        order: 3;
        height: 500px;
        margin: 0 -26px;
    }

    .contacts__full-list__main-row li span,
    .shortitem a,
    .shortitem span {
        font-size: 18px;
        max-width: 350px;
    }

    .shortitem {
        gap: 24px;
    }

    .shortitem__icon {
        height: 40px;
        flex: 0 0 40px;
    }

    .contacts__full-list__main-row {
        flex-direction: row;
        justify-content: space-between;
    }

    .contacts__full-list__sub-row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 90px;

        .footer__row {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 1280px) {
    .contacts {
        padding-top: 150px;
    }

    .contacts__map {
        margin: 0 -46px;
    }

    .contacts__title {
        margin-bottom: 56px;
    }
}

@media (min-width: 1440px) {
    .contacts__shortlist {
        gap: 30px;
        padding-bottom: 56px;
        margin-left: 35%;
    }

    .shortitem a,
    .shortitem span {
        font-size: 30px;
        max-width: 500px;
    }

    .contacts__full-list__main-row {
        justify-content: flex-start;
        gap: 90px;
    }

    .contacts__full-list__main-row li {
        flex: 0 0 205px;

        span {
            margin-bottom: 17px;
        }

        a {
            font-size: 18px;
        }
    }

    .contacts__full-list {
        padding-top: 50px;
        padding-bottom: 100px;
        margin-left: 35%;
    }

    .contacts__full-list__sub-row {
        margin-top: 50px;

        .button {
            text-transform: uppercase;
            padding: 21.5px 42.5px 19.5px;
        }
    }

    .contacts__map {
        height: 570px;
        margin: 0 -40px;
    }
}

@media (min-width: 1920px) {
    .contacts__map {
        width: calc(100% + 160px);
        margin: 0 -80px;
    }
}

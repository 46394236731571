.popup {
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease;
  padding: 50px 10px 70px;
  font-family: var(--font-helvetica), sans-serif;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 99;
  }
}

.result-message {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  font-size: 18px;
  font-family: inherit;

  h2 {
    font-size: 24px;
    font-weight: 600;
  }
}

.popup-window {
  background: #ffffff;
  position: relative;
  padding: 40px 15px 50px;
  max-width: 870px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  max-height: fit-content;
  overflow-y: auto;
}

.popup-window__close-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  right: 10px;
  top: 10px;

  svg {
    pointer-events: none;
    height: 20px;
    width: 20px;
  }
}

.popup-window__title {
  color: var(--color-darker);
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 28px;
}

.popup-window__form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form__submit-btn {
    margin: 22px auto 0;
    padding: 10.5px 25px;
  }
}

@media (min-width: 1024px) {
  .popup-window {
    padding: 65px 135px 65px;
  }

  .popup-window__close-btn {
    height: 40px;
    width: 40px;
    right: 20px;
    top: 20px;

    svg {
      height: 40px;
      width: 40px;
      line {
        stroke: #999999;
      }
    }
  }

  .popup-window__title {
    font-size: 60px;
    margin-bottom: 50px;
  }

  .popup-window__form {
    gap: 25px;

    .form__submit-btn {
      margin: 40px auto 0;
      padding: 21.5px 55px 22.5px;
    }
  }
}

.container {
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
}

@media (min-width: 768px) {
    .container {
        padding-left: 26px;
        padding-right: 26px;
    }
}

@media (min-width: 1280px) {
    .container {
        padding-left: 46px;
        padding-right: 46px;
    }
}

@media (min-width: 1440px) {
    .container {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (min-width: 1920px) {
    .container {
        padding-left: 80px;
        padding-right: 80px;
    }
}

.projects-section {
  padding-top: 42px;
  padding-bottom: 20px;
}

.projects-section__title {
  margin-bottom: 15px;
}

.projects-section__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  transition: all 0.2s ease;
  font-family: var(--font-helvetica);

  .projects-section__show-all {
    height: 97px;
  }

  .projects__link {
    height: 97px;
    border-right: none;
    box-shadow: 0 0 0 0.5px var(--color-darker);
    border: none !important;
  }

  .projects__link img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40%;
    width: 55%;
    object-fit: contain;
    transition: transform 0.5s ease;
  }
}

.projects-section__wrapper.grid {
  box-shadow: 0 0 0 1px var(--color-darker);
  gap: 0;
}

.projects-section__wrapper.list {
  display: flex;
  flex-wrap: wrap;
}

.projects__link {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--color-darker);
    font-family: var(--font-helvetica);
    font-size: 12px;
    font-weight: 300;
    line-height: 110%;
    text-transform: uppercase;
    margin: 15px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: transform 0.5s ease;
  }

  img {
    transition: transform 0.5s ease;
  }

  &:hover {
    span {
      transform: scale(1.1);
    }
    img {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}

.projects-section-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projects-section__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  margin-bottom: 15px;
}

.projects-section__show-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: 0 0 0 0.5px var(--color-darker);
  height: 97px;
  font-family: var(--font-helvetica);
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
  transition: all 0.18s ease;

  &:hover {
    background: var(--color-darker);
    color: #ffffff;
    svg {
      color: #ffffff;
    }
  }

  span {
    display: block !important;
    gap: 10px;
  }

  svg {
    display: none;
    color: #31a8fe;
  }
}

.projects-section__nav-btn:not(.projects-section__show-all) {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
  transition: all 0.2s ease;
}

.projects-section__nav-btn span {
  display: none;
}

.projects-section__nav-btn span,
.projects-section__nav-btn svg {
  pointer-events: none;
}

.projects-section__nav-btn.active {
  color: #31a8fe;
}

.projects-section__wrapper.grid .projects__links {
  display: none !important;
}

.projects-section__wrapper.list .projects__link {
  display: none !important;
}

.projects-section__wrapper.list {
  border-top: 1px solid #222222;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-right: -16px;
  margin-left: -16px;
}

.projects__links {
  width: 100%;
  padding: 20px 16px 28px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.93);
}

.projects__links b {
  border-left: 2px solid #31a8fe;
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
  text-transform: uppercase;
  margin-bottom: 21px;
  padding-left: 16px;
}

.projects__links-list {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.projects__links-list a {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #222222;
  transition: all 0.2s ease;
}

.projects__links-list a:hover {
  color: #31a8fe;
  text-decoration: underline;
}

@media (min-width: 480px) {
  .projects-section__wrapper {
    .projects-section__show-all,
    .projects__link {
      height: 148px;
    }
  }
}

@media (min-width: 768px) {
  .projects-section__wrapper.grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .projects-section__wrapper {
    .projects-section__show-all {
      height: 178px;
    }

    .projects__link {
      height: 178px;

      span {
        font-size: 16px;
      }
    }
  }

  .projects-section__show-all {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: fit-content;
    }

    span {
      display: flex !important;
      align-items: center;
      font-size: 20px;
    }
  }

  .projects__link {
    span {
      font-size: 16px;
    }
  }

  .projects-section__wrapper.list {
    margin-right: -26px;
    margin-left: -26px;
  }

  .projects__links {
    padding: 20px 26px 28px;
  }
}

@media (min-width: 1024px) {
  .projects-section__wrapper.grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .projects-section__wrapper {
    .projects-section__show-all,
    .projects__link {
      height: 178px;
    }
  }

  .projects-section__show-all svg {
    display: block;
  }

  .projects__links {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 65px 26px 50px;
    gap: 150px;
  }

  .projects__links b {
    flex: 0 0 40%;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    max-width: 185px;
    padding: 6px 30px;
  }

  .projects__links-list {
    gap: 20px;
    flex-grow: 1;
  }

  .projects__links-list a {
    font-size: 20px;
    line-height: 24px;
  }

  .projects-section__nav-btn:not(.projects-section__show-all) span {
    font-family: var(--font-helvetica);
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #cccccc;
    text-transform: uppercase;
    transition: all 0.2s ease;
  }

  .projects-section__nav-btn.active span {
    color: #31a8fe;
  }

  .projects-section__nav {
    gap: 47px;
    position: relative;

    button svg {
      display: none;
    }
  }

  .projects-section__nav::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 55.5%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 1px;
    background: #cccccc;
  }

  .projects-section__nav-btn:not(.projects-section__show-all) {
    flex: 0 0 auto;
    width: auto;
  }

  .projects-section__nav,
  .projects-section__title {
    margin-bottom: 36px;
  }
}

@media (min-width: 1280px) {
  .projects-section {
    padding-top: 130px;
    padding-bottom: 0;
  }

  .projects-section__wrapper {
    margin-left: -46px;
    margin-right: -46px;
  }

  .projects-section__wrapper {
    .projects-section__show-all,
    .projects__link {
      height: 256px;
      min-width: 15%;
    }
  }

  .projects-section__wrapper .projects__link {
    box-shadow: 0 1px 0px 0.5px var(--color-darker);
  }

  .projects__link {
    span {
      font-size: 18px;
      line-height: 125%;
      -webkit-line-clamp: 5;
    }
  }

  .projects__links {
    padding: 65px 46px 50px;
    gap: 150px;
  }

  .projects-section__wrapper.list {
    margin-right: -46px;
    margin-left: -46px;
  }

  .projects__links b {
    font-size: 20px;
    line-height: 28px;
    max-width: 200px;
  }

  .projects__links-list a {
    font-size: 30px;
    line-height: 40px;
  }

  .projects__links-list li {
    max-width: 960px;
  }

  .projects-section__show-all {
    font-size: 24px;

    span {
      font-size: 24px;
    }
  }
}

@media (min-width: 1440px) {
  .projects-section__wrapper.grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .projects-section__wrapper {
    .projects-section__show-all,
    .projects__link {
      height: 255px !important;
    }
  }

  .projects__links {
    gap: 300px;
  }

  .projects-section__wrapper.list {
    margin-right: -40px;
    margin-left: -40px;
    padding-bottom: 98px;
  }

  .projects-section__show-all {
    font-size: 28px;

    span {
      font-size: 28px;
    }
  }
}

@media (min-width: 1920px) {
  .projects-section__wrapper {
    .projects-section__show-all,
    .projects__link {
      height: 321px !important;
    }
  }

  .projects-section__wrapper.list,
  .projects-section__wrapper {
    margin-left: -80px;
    margin-right: -80px;
  }

  .projects__links {
    padding: 65px 80px 50px;
  }

  .projects__links {
    gap: 380px;
  }

  .projects-section__show-all {
    font-size: 30px;

    span {
      font-size: 30px;
    }
  }
}

@keyframes arrowSlide {
    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(10px, 0);
    }
}

.history {
    background: var(--color-dark);
    padding-top: 24px;
    padding-bottom: 56px;
}

.history__title {
    color: #ffffff;
    margin-bottom: 25px;
}

.history__slider {
    margin-right: -16px;
    margin-left: -16px;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 100%;
}

.history__slide {
    font-family: var(--font-helvetica);
    width: 100%;
}

.history__slide-top {
    position: relative;
    width: 212px;
    padding-bottom: 20px;

    &::after {
        content: '';
        position: absolute;
        width: 110px;
        height: 110px;
        border-radius: 500px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(47, 50, 55, 0.8) 0%, rgba(47, 50, 55, 0) 100%);
        bottom: -30px;
        right: -44px;
        z-index: 1;
    }
}

.history__slide-image {
    width: 212px;
    height: 140px;
}

.history__slide-date {
    position: absolute;
    color: #ffffff;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 50px;
    font-style: italic;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 1px;
    bottom: -3px;
    right: -44px;
    z-index: 2;
}

.history__slide-figure {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: calc(100% - 48px);
        background: #ffffff;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }

    span {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        border: 1px solid #ffffff;
        border-radius: 50%;

        &::after {
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #ffffff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.history__slide__text {
    margin-top: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.28px;
    width: 300px;
}

.history__hint {
    display: none;
}

@media (min-width: 768px) {
    .history {
        padding-top: 44px;
        padding-bottom: 76px;
    }

    .history__slider {
        margin-right: -26px;
        margin-left: -26px;
        padding-left: 26px;
        padding-right: 26px;
    }

    .history__slide-image {
        width: 312px;
        height: 210px;
    }

    .history__slide-top {
        width: 312px;
    }
}

@media (min-width: 1024px) {
    .history__slider {
        margin-right: -46px;
        margin-left: -46px;
        padding-left: 46px;
        padding-right: 46px;
    }

    .history__slide-image {
        width: 430px;
        height: 290px;
    }

    .history__slide-top {
        width: 430px;
    }

    .history__slide__text {
        font-size: 16px;
    }

    .history__slide-date {
        font-size: 80px;
        bottom: -15px;
        right: -70px;
    }

    .history__slide-top::after {
        width: 150px;
        height: 150px;
        bottom: -45px;
        right: -60px;
    }
}

@media (min-width: 1280px) {
    // .history__slider {
    //     margin-left: -46px;
    //     padding-left: 46px;
    // }

    .history__slide-figure {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        span {
            width: 57px;
            height: 57px;

            &::after {
                height: 10px;
                width: 10px;
            }
        }

        &::after {
            left: -45px;
            right: auto;
            // width: 600px;
        }
    }

    .history__slide-top {
        margin-bottom: 80px;
        width: 100%;
    }

    .history__slide-image {
        width: 500px;
        height: 320px;
    }

    .history__slide-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;
        max-width: 1030px;
    }

    .history__slide__text {
        margin-top: 0;
        flex: 0 0 290px;
    }

    .history__slide-date {
        font-size: 115px;
        letter-spacing: 2.6px;
        bottom: -50px;
        right: 17px;
    }

    .history__slide-top::after {
        width: 300px;
        height: 300px;
        bottom: -135px;
        right: -15px;
    }

    .history__title {
        margin-bottom: 50px;
    }

    .history__hint {
        font-family: var(--font-helvetica);
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.32px;
        gap: 18px;

        svg {
            animation: arrowSlide 1s ease-in-out infinite;
            width: 49px;
            height: 38px;
        }
    }

    .history__header {
        display: flex;
        gap: 220px;
    }
}

@media (min-width: 1920px) {
    .history {
        padding-top: 80px;
        padding-bottom: 180px;
    }

    .history__title {
        margin-bottom: 80px;
    }

    .history__slide-image {
        width: 570px;
        height: 380px;
    }

    .history__slide-date {
        font-size: 130px;
        letter-spacing: 2.6px;
        bottom: -50px;
        right: 90px;
    }

    .history__slide-top::after {
        width: 300px;
        height: 300px;
        bottom: -115px;
        right: 90px;
    }

    .history__slide-figure {
        flex: 1 0 auto;
    }

    .history__slide__text {
        flex: 0 0 275px;
    }

    .history__slide-figure::after {
        left: -80px;
        // width: 505px;
    }

    .history__slide-bottom {
        gap: 30px;
    }

    .history__slider {
        margin-left: -80px;
        margin-right: -80px;
        padding-left: 80px;
        padding-right: 80px;
    }
}

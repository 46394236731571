@charset "UTF-8";

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  min-width: 320px;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  background: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --trans: 0.18s ease;

  --font-inter: "Inter", sans-serif;
  --font-helvetica: "HelveticaNeue", sans-serif;
  --font-helios: "Helios", sans-serif;

  --color-dark: #2f3237;
  --color-darker: #222222;
  --color-blue: #31a8fe;
}

body.menu-shown,
body.lock {
  overflow: hidden;
}

.wrapper {
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.page {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.grecaptcha-badge {
  display: none;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  transition: all var(--trans);
  padding-bottom: 7.5px;
  overflow: hidden;
  max-width: 40px;
  width: 40px;
}

.image {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.link {
  color: var(--color-blue);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.search-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #000000;
  transition: all var(--trans);
}

.search-wrapper input {
  color: #000000;
  font-family: var(--font-helvetica);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  min-width: 0px;
  flex: 0 1 0px;
  transition: all var(--trans);
  background: transparent;
}

.search-wrapper input::placeholder {
  color: #999999;
}

.search-wrapper button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.search-wrapper.active {
  width: 100%;
  flex-grow: 1;
  max-width: 100%;
  overflow: unset;
}

.search-wrapper.active input {
  margin-right: 15px;
  flex: 1 0 auto;
  z-index: 2;
}

.search-wrapper.active button {
  z-index: 2;
}

.search-wrapper.active::after {
  width: 97%;
}

.slider-button-prev,
.slider-button-next {
  cursor: pointer;
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  background: url("../img/icons/arrow-right.svg") center no-repeat;
  transition: opacity var(--trans);

  &:hover {
    opacity: 0.7;
  }
}

.slider-button-prev {
  transform: rotate(180deg);
}

.slider-button-prev_thin,
.slider-button-next_thin {
  cursor: pointer;
  height: 15.5px;
  flex: 0 0 20px;
  width: 20px;
  background: url("../img/icons/arrow-right_thin.svg") center no-repeat;
  background-size: 100%;
  transition: opacity var(--trans);
}

.slider-button-prev.swiper-button-lock,
.slider-button-next.swiper-button-lock,
.slider-button-prev_thin.swiper-button-lock,
.slider-button-next_thin.swiper-button-lock {
  display: none !important;
}

.slider-button-prev.swiper-button-disabled,
.slider-button-next.swiper-button-disabled,
.slider-button-prev_thin.swiper-button-disabled,
.slider-button-next_thin.swiper-button-disabled {
  opacity: 0.4;
}

.slider-button-prev_thin {
  transform: rotate(180deg);
}

.title {
  font-family: var(--font-helvetica);
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.breadcrumbs {
  display: none;
}

.hover-list__item {
  position: relative;
}

.ymaps-2-1-79-map-copyrights-promo {
  display: none;
}

.search-wrapper {
  .search-result {
    flex-direction: column;
    gap: 5px;
    display: none;
    position: absolute;
    width: 100%;
    background: #ffffff;
    z-index: 2;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    height: fit-content;
    max-height: 350px;
    border-bottom: 1px solid var(--color-darker);
    overflow-y: auto;
    transition: all var(--trans);
    border-radius: 3px;

    a {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: var(--color-darker);
      font-family: var(--font-helvetica), sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      transition: all var(--trans);
      padding: 15px 10px;

      &:hover {
        background: #ededed;
      }

      span {
        color: #999999;
      }
    }
  }

  &.active .search-result {
    display: flex;
  }
}

.form {
  font-family: var(--font-helvetica);
  padding-top: 30px;
  padding-bottom: 45px;
  max-width: 600px;
  margin: 0 auto;

  small {
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 110%;
    margin-bottom: 20px;
    text-align: center;
  }

  form {
    display: grid;
    gap: 30px;

    .err-message {
      margin-top: -10px;
    }

    .form__submit-btn {
      margin: -10px auto 0;
    }
  }

  &__label {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 36px;
  }

  .form {
    &__subtitle {
      font-size: 16px;
      margin-bottom: 50px;
    }
  }
}

@media (min-width: 1280px) {
  .search-wrapper {
    .search-result {
      border-bottom: none;
      top: calc(100% + 20px);
    }
  }

  .search-wrapper::before {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 1;
    transition: all var(--trans);
  }

  .search-wrapper.active::before {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }

  .breadcrumbs-container {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    padding-left: 46px;
  }

  .breadcrumbs {
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 7px;
    color: #999999;
    margin-bottom: 110px;
    margin-top: 9px;
    list-style: none;
    top: 0;
    max-width: 480px;
  }

  .breadcrumbs * {
    font-family: var(--font-helvetica);
    font-size: 12px;
    font-weight: 300;
    line-height: 250%;
    letter-spacing: 0.24px;
    color: #999999;
  }

  .breadcrumbs {
    span,
    b {
      color: var(--color-darker);
    }

    a:hover {
      color: var(--color-blue);
      text-decoration: underline;
      transition: all var(--trans);
    }
  }

  .search-wrapper.active {
    max-width: 230px;
  }

  .title {
    font-size: 32px;
  }

  .search-wrapper {
    padding-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .breadcrumbs-container {
    padding-left: 40px;
  }

  .slider-button-prev_thin,
  .slider-button-next_thin {
    flex: 0 0 49px;
    width: 49px;
    height: 38px;
  }

  .slider-button-prev,
  .slider-button-next {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    background-size: 55%;
  }

  .title {
    font-size: 48px;
  }
}

@media (min-width: 1920px) {
  .breadcrumbs-container {
    padding-left: 80px;
  }

  .title {
    font-size: 60px;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideZoom {
  0% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 105%;
    height: 105%;
  }
}

@keyframes slideOut {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  padding: 0;
  list-style: none;
  z-index: 1;
  display: block;
  max-height: fit-content;
}

.main-page .container {
  max-width: 2560px;
}

.main-section__container {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  max-width: 2560px;
}

.main-slider {
  height: 100vh;
  min-height: 450px;
  z-index: 11;
}

.main-slider::before,
.main-slider::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: transform var(--trans);
  will-change: transform;
  z-index: 11;
}

.main-slider::before {
  background: #262626;
  animation: slideOut cubic-bezier(0.44, 0.12, 0.58, 1) 0.7s forwards 0.5s;
  z-index: 12;
}

.main-slider::after {
  background: #7d8187;
  animation: slideOut cubic-bezier(0.65, -0.07, 0.58, 1) 0.75s forwards 0.7s;
}

.main-slider__slide {
  position: relative;
}

.main-slider__slide::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(42, 49, 68, 0.7);
}

.main-slider__slide img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.main-slider__slide.swiper-slide-active img {
  animation: slideZoom ease 6s forwards 0.3s;
}

.main-section .main-slide__title span,
.main-section .main-slide__list-item span,
.main-section .main-slide__list-item strong {
  display: inline-block;
  animation: slideUp cubic-bezier(0.65, -0.07, 0.58, 1) 1s forwards 0s;
  transform: translateY(100%);
}

.animate-text {
  overflow-y: hidden;
}

.main-section .animate-text {
  padding-top: 8px;
}

.main-slide__content {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 11;
}

.main-slide__title {
  font-family: var(--font-helvetica);
  color: #ffffff;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  max-width: 350px;
}

.main-slide__list {
  display: none;
}

.main-slider__nav {
  position: absolute;
  bottom: 23.5px;
  left: 17px;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 12;
}

.main-slider__nav .swiper-pagination {
  position: relative;
  color: #ffffff;
  font-family: var(--font-helvetica);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  bottom: auto;
  top: auto;
  left: auto;
}

.main-slider__nav .fract-total {
  font-size: 10px;
}

.main-description__container {
  padding-top: 92px;
  padding-bottom: 72px;
}

.main-description__text {
  color: var(--color-darker);
  font-family: var(--font-helios);
  font-size: 32px;
  font-weight: 300;
  line-height: 110%;
  margin-bottom: 23px;
}

.main-description__link {
  margin-left: auto;
}

.activities {
  background: var(--color-dark);
  margin: 0 auto;
}

.activities__wrapper {
  padding-top: 45px;
  padding-bottom: 62px;
}

.activities__header {
  padding-bottom: 50px;
}

.activities__title {
  color: #ffffff;
  margin-bottom: 28px;
  line-height: 1.15;
  letter-spacing: 0.05em;
}

.activities__header .button {
  padding: 10.5px 19.5px;
}

.activities__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ffffff;
  counter-reset: item;

  li {
    border-bottom: 1px solid #ffffff;
    position: relative;
    transition: all var(--trans);

    &:hover {
      background: #ffffff;

      &::before {
        color: var(--color-dark);
      }

      a {
        color: var(--color-dark);
        background-image: url("../img/icons/arrow-link_black.svg");
      }
    }

    &::before {
      position: absolute;
      content: "";
      color: rgba(255, 255, 255, 0.3);
      font-family: var(--font-helvetica);
      font-size: 10px;
      font-weight: 300;
      line-height: normal;
      left: 15px;
      top: 25px;
      counter-increment: item;
      content: counter(item, decimal-leading-zero) "";
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 22px 12px 28px 34px;
      color: #ffffff;
      font-family: var(--font-helvetica);
      font-size: 24px;
      font-weight: 300;
      line-height: normal;
      background: url("../img/icons/arrow-link.svg") right no-repeat;
      background-size: 18px;
      background-position: right 12px top 50%;
    }
  }
}

.links__container {
  padding-left: 0;
  padding-right: 0;
}

.links__wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

.links__item {
  position: relative;
  height: 320px;
  padding: 31px 16px;
  overflow: hidden;
}

.links__item::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(62, 76, 115, 0.5);
  z-index: -1;
  transition: opacity 1s ease;
}

.links__item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  transition: transform 1s ease;
}

.links__item-name {
  color: #ffffff;
  font-family: var(--font-helvetica);
  font-size: 32px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 14px;
}

.links__item-link {
  border: 1px solid #ffffff;
}

.links__item-link svg path {
  stroke: #ffffff;
}

.links__item-link:hover {
  background: transparent;
}

.links__item:hover::after {
  opacity: 0;
}

.links__item:hover img {
  transform: translate(-50%, -50%) scale(1.1);
}

.activities {
  max-width: 2560px;
}

.projects {
  max-width: 1920px;
  margin: 0 auto;
}

.projects {
  padding-top: 43px;
  padding-bottom: 60px;
}

.projects__title {
  position: relative;
  color: var(--color-darker);
  padding-bottom: 13.5px;
  margin-bottom: 41px;
}

.projects__title::after {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: var(--color-darker);
}

.projects__carousel {
  border-top: 1px solid var(--color-darker);
  border-bottom: 1px solid var(--color-darker);
  opacity: 0;
  transition: opacity var(--trans);
}

.projects__link {
  position: relative;
  display: block;
  height: 136px;
  border-right: 1px solid var(--color-darker);
  transition: all var(--trans);
  filter: grayscale(1);

  &:hover {
    filter: none;
  }
}

.projects__carousel-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.projects__slider_2,
.projects__slider_3 {
  display: none !important;
}

.projects__column .projects__link:first-of-type {
  border-left: 1px solid var(--color-darker);
}

.projects__slider_2 .projects__link,
.projects__slider_3 .projects__link {
  border-right: none;
}

.projects__link img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 50%;
  object-fit: contain;
}

.guide {
  position: relative;
  overflow: hidden;

  .simpleParallax,
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.guide::after {
  content: "";
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: rgba(42, 49, 68, 0.8);
}

.guide__container {
  padding-top: 41px;
  padding-bottom: 54px;
}

.guide__text {
  color: #ffffff;
  font-family: var(--font-helvetica);
  font-size: 32px;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 24.5px;
  text-wrap: balance;
}

.guide__link {
  padding: 10.5px 19.5px;
  border-color: #ffffff;
  color: #ffffff;
  background: transparent;
}

.guide__link:hover {
  background: #ffffff;
  color: var(--color-dark);
}

.specialists__image {
  position: relative;
  min-width: 100%;
  height: 320px;
  margin-left: -16px;
  margin-right: -16px;
}

.specialists__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.specialists__image:after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: #152655;
  z-index: -1;
}

.specialists__content {
  padding-top: 20px;
  padding-bottom: 76px;
  font-family: var(--font-helvetica);
  color: var(--color-darker);
}

.specialists__title {
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.specialists__subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

.specialists__link {
  margin-left: auto;
}

.press-center__header {
  padding-top: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.press-center__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.press-center__slider.swiper {
  position: relative;
  padding-top: 20px;
  padding-bottom: 22px;
  margin-right: -16px;
  margin-left: -16px;
}

.press-center__slider.swiper::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 100vw;
  background: #000000;
  top: 0;
  left: 0;
  right: 0;
}

@-moz-document url-prefix() {
  .press-center__slider.swiper {
    .swiper-wrapper {
      max-height: 355px;
    }
  }
}

.press-center__slide {
  border-left: 1px solid var(--color-darker);
  padding: 23.5px 35px 32.5px 16px;
  font-family: var(--font-helvetica);
  color: var(--color-darker);
  transition: background 0.5s ease;
  min-height: 150px;
}

.press-center__slide:hover {
  background: #f6f6f6;
}

.press-slide__date {
  margin-bottom: 4px;
}

.press-slide__date b {
  font-size: 30px;
  font-weight: 300;
  line-height: normal;
}

.press-slide__date span {
  font-size: 10px;
  font-weight: 400;
  line-height: 100%;
  opacity: 0.3;
}

.press-slide__text {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.press-center__slide.swiper-slide-active,
.press-center__slide.swiper-slide-next {
  opacity: 1 !important;
}

.press-center__slide:not(.swiper-slide-active),
.press-center__slide:not(.swiper-slide-next) {
  opacity: 0.5;
}

@media (min-width: 480px) {
  .main-slide__title {
    font-size: 36px;
  }

  .specialists__subtitle {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .main-slider {
    min-height: 580px;
  }

  .main-slide__content {
    justify-content: space-between;
    padding-left: 26px;
    padding-right: 26px;
  }

  .main-slider__nav {
    left: 26px;
  }

  .main-slide__title {
    font-size: 38px;
    max-width: 330px;
  }

  .main-slide__list {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .main-slider__slide:first-of-type .main-slide__list-item .animate-text {
    padding-left: 86px;
    margin-left: -86px;
  }

  .main-slide__title .animate-text {
    padding-top: 10px;
  }

  .main-slide__list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: var(--font-helvetica);
    color: #fff;
    font-style: normal;
  }

  .main-slide__list-item strong svg {
    position: absolute;
    top: 10px;
    left: -45px;
    width: 35px;
    height: 35px;
  }

  .main-slide__list-item .plus_right svg {
    left: 110%;
    top: 0;
  }

  .main-slide__list-item strong {
    font-size: 28px;
    font-weight: 300;
    position: relative;
    width: fit-content;
    white-space: nowrap;
  }

  .main-slide__list-item strong b {
    font-size: 48px;
    font-weight: 300;
    line-height: normal;
  }

  .main-slide__list-item strong i {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.8%;
    text-transform: uppercase;
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
  }

  .main-slide__list-item span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    max-width: 220px;
  }

  .press-slide__text {
    font-size: 16px;
  }

  .press-center__slider.swiper {
    margin-right: -26px;
    margin-left: -26px;
  }

  .main-description__text {
    font-size: 48px;
    max-width: 620px;
  }

  .activities__list li a {
    font-size: 36px;
    background-size: 24px;
  }

  .activities__list li::before {
    font-size: 12px;
  }

  .specialists__image {
    margin-left: -26px;
    margin-right: -26px;
  }

  .specialists__content {
    padding-top: 40px;
  }

  .specialists__title {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .specialists__subtitle {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .guide__text {
    font-size: 48px;
  }

  .guide__container {
    padding-top: 61px;
    padding-bottom: 64px;
  }

  .projects__link {
    height: 206px;
  }
}

@media (min-width: 1024px) {
  .projects__link {
    height: 236px;
  }
}

@media (min-width: 1280px) {
  .main-slide__title .animate-text {
    padding-top: 18px;
  }

  .main-slide__title {
    font-size: 70px;
    max-width: 700px;
  }

  .main-slide__list {
    gap: 50px;
  }

  .main-slide__list-item span {
    font-size: 18px;
  }

  .main-slide__list-item strong b {
    font-size: 80px;
  }

  .main-slide__list-item strong {
    font-size: 38px;
  }

  .main-slide__list-item strong svg {
    top: 25px;
    left: -60px;
    width: 50px;
    height: 50px;
  }

  .main-slide__list-item strong i {
    font-size: 16px;
  }

  .main-slider__nav {
    left: 46px;
    bottom: 54px;
    gap: 47px;
  }

  .main-slider__nav .swiper-pagination {
    font-size: 38px;
  }

  .main-slider__nav .fract-total {
    font-size: 18px;
  }

  .main-slide__content {
    padding-left: 46px;
    padding-right: 46px;
  }

  .main-slider {
    min-height: 780px;
  }

  .main-description__text {
    font-size: 78px;
    max-width: 1000px;
  }

  .press-center__slide {
    padding: 50px 75px 80px 50px;
    min-height: 352px;
  }

  .press-center__slide.swiper-slide-next + .press-center__slide {
    opacity: 1 !important;
  }

  .press-slide__date b {
    font-size: 70px;
  }

  .press-slide__date {
    margin-bottom: 12px;
  }

  .press-slide__date span {
    font-size: 20px;
    line-height: 30px;
  }

  .press-slide__text {
    font-size: 20px;
    line-height: 150%;
    -webkit-line-clamp: 4;
  }

  .press-center__slider.swiper {
    margin-right: -40px;
    margin-left: -40px;
  }

  .activities__wrapper {
    justify-content: space-between;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }

  .activities__body {
    flex-grow: 1;
    padding-top: 67px;
    padding-bottom: 67px;
    border-left: 1px solid #ffffff;
  }

  .activities__header {
    flex: 0 0 470px;
    padding-top: 130px;
    padding-bottom: 50px;
  }

  .projects {
    display: flex;
    padding-right: 95px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .projects__title {
    max-width: 375px;
  }

  .projects__container {
    margin: 0 !important;
    padding-right: 0;
    padding-left: 0;
    padding-top: 94px;
    flex: 0 0 470px;
    padding-left: 80px;
  }

  .projects__link img {
    width: 55%;
    height: 45%;
  }

  .projects__slider_2,
  .projects__slider_3 {
    display: block !important;
  }

  .projects__slider {
    flex: 1 0 236px;
    height: 680px;
    min-width: 236px;
    border-right: 1px solid var(--color-darker);
    margin: 0 !important;
  }

  .projects__slider_1 {
    border-left: 1px solid var(--color-darker);
    border-right: none;
  }

  .projects__carousel-wrapper {
    border-top: none;
    border-bottom: none;
  }

  .projects__carousel {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    flex-grow: 1;
  }

  .projects__link {
    border-left: none !important;
    border-top: 1px solid var(--color-darker);
  }

  .links__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .links__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .links__item {
    height: 915px;
    padding: 60px 65px;
  }

  .links__item-name {
    font-size: 48px;
    font-weight: 100;
    margin-bottom: 39px;
  }

  .specialists__wrapper {
    display: flex;
  }

  .specialists__image {
    flex: 0 0 700px;
    height: 684px;
    min-width: 50%;
    margin: 0;
  }

  .specialists__container {
    padding-left: 0;
  }

  .specialists__content {
    flex-grow: 1;
    padding-top: 140px;
    padding-left: 70px;
  }

  .specialists__title {
    font-size: 48px;
    margin-bottom: 30px;
  }

  .specialists__subtitle {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 60px;
    max-width: 400px;
  }

  .specialists__link {
    margin-left: 0;
  }

  .guide__text {
    max-width: 800px;
    font-size: 54px;
  }

  .guide__container {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1440px) {
  .activities {
    height: 100%;
    min-height: 100%;
  }

  .press-center {
    height: 960px;
    min-height: unset;
  }

  .main-description,
  .projects,
  .links,
  .guide,
  .specialists {
    height: 100vh;
    min-height: 900px;
  }

  .activities__list li a {
    padding: 55px 68px 50px 100px;
    background-position: top 50% right 68px;
    background-size: 35px;
  }

  .activities__list li::before {
    top: 50px;
    left: 58px;
  }

  .links__item {
    padding: 80px 85px;
  }

  .links__item-name {
    font-size: 60px;
    font-weight: 100;
    margin-bottom: 39px;
  }

  .press-center__container {
    height: calc(100% - 157px);
  }

  .press-center__slider.swiper {
    // height: 100%;
    padding-left: 200px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .press-center__nav {
    gap: 47px;
  }

  .press-center__header {
    padding-top: 80px;
    padding-bottom: 65.5px;
  }

  .specialists__image {
    min-height: 900px;
    flex: 0 0 800px;
  }

  .guide__text {
    font-size: 64px;
  }

  .guide__container {
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .activities__header,
  .projects__container {
    flex: 0 0 603px;
  }

  .projects {
    max-height: 900px;
  }

  .projects__slider {
    min-width: 245px;
    flex-grow: 1;
    height: 100%;
  }

  .projects__link {
    min-width: 245px;
  }
}

@media (min-width: 1920px) {
  .main-slider,
  .links,
  .guide,
  .specialists,
  .specialists__image {
    min-height: 980px;
  }

  .main-description {
    min-height: 1080px;
  }

  .projects {
    min-height: 1080px;
    max-height: 1080px;
  }

  .main-section .animate-text {
    padding-left: 86px;
    margin-left: -86px;
  }

  .main-slide__title {
    font-size: 100px;
    max-width: 900px;
  }

  .main-slider__nav {
    left: 50%;
    bottom: 64px;
    justify-content: flex-start;
    width: 100%;
    max-width: 1775px;
    transform: translateX(-50%);
  }

  .main-slider__nav .swiper-pagination {
    font-size: 50px;
    width: fit-content;
  }

  .main-slider__nav .fract-total {
    font-size: 20px;
  }

  .main-slide__content {
    gap: 480px;
    padding: 20px 80px 30px;
  }

  .main-slider__slide:first-of-type .main-slide__content {
    gap: 500px;
  }

  .main-slide__list {
    display: flex;
    flex-direction: column;
    gap: 85px;
  }

  .main-slide__list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: var(--font-helvetica);
    color: #fff;
    font-style: normal;
  }

  .main-slide__list-item strong svg {
    position: absolute;
    top: 40px;
    left: -85px;
    width: 70px;
    height: 70px;
  }

  .main-slide__list-item .plus_right svg {
    top: 30px;
  }

  .main-slide__list-item strong {
    font-size: 70px;
    font-weight: 300;
    position: relative;
    width: fit-content;
    white-space: nowrap;
  }

  .main-slide__list-item strong b {
    font-size: 120px;
    font-weight: 300;
    line-height: normal;
  }

  .main-slide__list-item strong i {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.8%;
    text-transform: uppercase;
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
  }

  .main-slide__list-item span {
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    max-width: 250px;
  }

  .main-description__container {
    padding-top: 265px;
    padding-bottom: 220px;
    padding-left: 75px;
  }

  .main-description__text {
    font-size: 110px;
    max-width: 1400px;
  }

  .main-description__link {
    margin-top: -50px;
  }

  .activities__wrapper {
    justify-content: center;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 auto;
  }

  .activities__container {
    margin-left: auto;
    max-width: 634px;
  }

  .activities__title {
    font-size: 60px;
    margin-bottom: 60px;
  }

  .activities__header .button {
    padding: 21.5px 44.5px;
  }

  .activities__list li::before {
    font-size: 14px;
  }

  .activities__list li a {
    font-size: 50px;
    background-size: 38px;
  }

  .projects {
    gap: 34px;
    padding-right: 115px;
  }

  .projects__container {
    flex: 0 0 634px;
    max-width: 634px;
  }

  .activities__header {
    flex: 0 0 33%;
  }

  .projects__container {
    flex: 0 0 600px;
  }

  .projects__carousel-wrapper {
    width: 100%;
  }

  .projects__slider {
    min-width: 384px;
    flex-grow: 1;
    height: 1080px;
  }

  .projects__link {
    min-width: 384px;
  }

  .guide__container {
    padding-top: 180px;
    padding-bottom: 160px;
  }

  .guide__text {
    font-size: 100px;
    max-width: 1470px;
    margin-bottom: 60px;
  }

  .links__container,
  .specialists__container {
    max-width: 2560px;
  }

  .specialists__image {
    flex: 1 0 1170px;
    height: 884px;
  }

  .specialists__content {
    padding-top: 198px;
    padding-left: 105px;
  }

  .specialists__title {
    font-size: 60px;
  }

  .specialists__subtitle {
    margin-bottom: 80px;
  }

  .press-center__slider.swiper {
    padding-left: 260px;
    margin-left: -80px;
    margin-right: -80px;
  }

  .links__item {
    height: 980px;
  }

  .press-center__container {
    height: calc(100% - 170px);
  }
}

@media (min-width: 2560px) {
  .main-slide__content {
    gap: 590px;
  }

  .main-description__container,
  .guide__container {
    padding-left: 380px;
    padding-right: 380px;
  }

  .activities__header {
    flex: 0 0 37.3%;
  }

  .guide,
  .links,
  .main-slider,
  .specialists,
  .specialists__image,
  .links__item {
    height: 1320px;
  }
}

.card {
  padding-bottom: 40px;
}

.card__wrapper {
  font-family: var(--font-helvetica);
}

.card__preview-block {
  position: relative;
  width: 100vw;
  height: 210px;
  margin: 0 -16px 25px;

  .card-button-next,
  .card-button-prev {
    display: none;
  }

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.card__preview-slide {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    min-height: 210px;
    width: 100%;
    object-fit: cover;
  }
}

.card__title-block {
  margin-bottom: 26px;

  .button {
    font-size: 10px;
    font-weight: 500;
  }
}

.card__link {
  display: block;
  color: var(--color-darker);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: underline;
  margin-bottom: 15px;
  background: url("../img/icons/link-arrow.svg") right no-repeat;
  background-size: 10px;
  width: fit-content;
  padding-right: 18px;
}

.card__main-content {
  margin-bottom: 28px;
  color: var(--color-darker);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.28px;

  b {
    font-weight: 600;
  }

  & > * {
    margin-bottom: 20px;
  }

  ol {
    margin-left: 20px;
  }
}

.card__aside-content {
  margin-bottom: 26px;
}

.events {
  .card__aside-content {
    margin-bottom: 0;
  }
}

.card__list_blue {
  display: flex;
  flex-direction: column;
  gap: 23px;

  li {
    color: var(--color-darker);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.28px;
    padding: 8px 0 12px 18px;
    border-left: 2px solid #31a8fe;

    strong {
      display: block;
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.28px;
      text-transform: uppercase;
    }

    b {
      color: var(--color-darker);
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.28px;
    }

    div {
      margin-top: 12px;

      a[href^="tel:"] {
        text-decoration: none;
      }

      a {
        margin-bottom: 0;
        padding: 0;
        background: none;
        font-weight: 300;
      }
    }
  }
}

.card__bottom-content {
  .button {
    font-size: 10px;
    font-weight: 500;
    line-height: 16.5px;
  }
}

.advertisement {
  .card__title {
    margin-bottom: 16px;
  }
}

.card__info-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;
  margin-bottom: 31px;

  li {
    display: flex;
    flex-direction: column;
    color: var(--color-darker);
    position: relative;

    &:not(.empty)::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 30px;
      background: #31a8fe;
      left: 0;
      bottom: -6px;
    }

    strong {
      display: flex;
      align-items: baseline;
      gap: 2.5px;
    }

    span {
      color: #999999;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    b {
      font-size: 30px;
      font-weight: 300;
      line-height: normal;
    }

    i {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.news-detail__date {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 0 !important;
}

.career {
  * {
    font-family: var(--font-helvetica);
  }

  .card__title {
    margin-bottom: 15px;
  }

  .card__image {
    position: relative;
    flex: 0 0 137px;
    height: 190px;
  }

  .card__main-content {
    display: flex;
    gap: 63px;
  }

  .card__main-content {
    flex-direction: column;
    gap: 20px;
  }

  .card__text p:last-of-type {
    display: none;
  }

  .card__content-wrapper_gray {
    margin-bottom: 32px;
    max-width: 1920px;
    margin: 0 auto;
  }

  .card__aside-content > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .card__bottom-content {
    grid-column: span 2;
  }
}

.career-info {
  .card__link.image {
    width: 70px;
    height: 41px;
  }

  .card__list_blue li {
    border-left: none;
    padding: 8px 0 12px 20px;

    b {
      display: block;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        height: calc(100% + 10px);
        width: 2px;
        background: #31a8fe;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.card__content-wrapper_gray {
  padding: 44px 15px 38px;
  margin-left: -16px;
  margin-right: -16px;
}

.card__main-content_gray {
  & > p {
    font-size: 20px;
    font-weight: 300 !important;
    line-height: 28px;
    color: var(--color-darker);
    margin-bottom: 36px;
  }

  .card__info-list {
    grid-template-columns: 1fr;
    margin-bottom: 0;

    * {
      color: var(--color-darker);
    }
  }

  .card__info-list li span {
    position: relative;
    font-size: 24px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 35px;
    text-transform: none;

    &::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 50px;
      background: #31a8fe;
      left: 0;
      bottom: -15px;
    }
  }

  .card__info-list li p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  .card__info-list li:not(.empty)::after {
    display: none;
  }
}

.card__main-content ol {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--color-darker);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.28px;
  margin-bottom: 24px;
}

.card__main-content ul,
.card__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--color-darker);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.28px;
  margin-bottom: 24px;

  li {
    padding-left: 20px;
    position: relative;
    list-style: none;

    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      width: 5px;
      height: 5px;
      background: var(--color-darker);
      border-radius: 50%;
    }
  }
}

.card__author {
  padding-top: 17px;
  color: #999999;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  border-top: 1px solid #eeeeee;
}

.card__bottom-content {
  .card__large-list {
    margin-bottom: 30px;
  }

  .contacts__full-list__main-row {
    gap: 24px;
    margin: 20px 0 30px;

    li span {
      margin-bottom: 0;
    }

    li,
    * {
      list-style: none;
      color: var(--color-darker);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  p {
    color: var(--color-darker);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.card__large-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--color-darker);

  li {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 30px;

    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      height: 1px;
      width: 20px;
      background: var(--color-darker);
    }
  }
}

.card__large-list-title {
  color: #222;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
}

.about {
  .card__title {
    margin-bottom: 11px;
  }

  .card__title-block {
    p {
      color: var(--color-darker);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  .card__content-wrapper {
    display: flex;
    flex-direction: column;

    .card__aside-content {
      order: 1;
      margin-bottom: 40px;
    }

    .card__main-content {
      order: 2;
    }
  }

  .card__list_blue li {
    padding: 4px 0 4px 10px;

    strong {
      color: var(--color-darker);
      font-size: 10px;
      line-height: 110%;
      text-transform: uppercase;
      max-width: 70px;
    }
  }
}

.card__list_blue a {
  text-decoration: underline;
  color: var(--color-blue);

  &:hover {
    text-decoration: none;
  }
}

.card__video {
  position: relative;
  height: 200px;
  width: 290px;
  margin: 0 auto 10px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.card__video-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  background: transparent url("../img/icons/video-play.svg") center no-repeat;
  background-size: 19px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.about__info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
}

.about__title {
  color: var(--color-darker);
  font-size: 24px;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
}

.about__photo {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 426px;
  margin: 0 auto;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.card__main-content {
  a {
    text-decoration: underline;
    color: var(--color-blue);

    &:hover {
      text-decoration: none;
    }
  }
}

.card__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.card__text {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.person-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

.person-block span {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #666666;
}

.person-block b {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-darker);
  margin-top: 5px;
}

.about {
  .card__content-wrapper .card__aside-content {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  .card__content-wrapper .card__aside-content span {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
  }
  .card__content-wrapper .card__aside-content span::before {
    content: "";
    display: block;
    width: 2px;
    height: 60px;
    background-color: #31a8fe;
  }
}

@media (max-width: 767px) {
  .about .card__content-wrapper .card__aside-content {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) {
  .card__info-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .card__preview-block,
  .card__wrapper .simpleParallax {
    height: 300px;
  }

  .about {
    .simpleParallax,
    .card__preview-block {
      height: 280px;
      margin-bottom: 40px;
    }

    .card__list_blue li strong {
      font-size: 14px;
    }
  }

  .card__video {
    height: 270px;
    width: 440px;
  }

  .about__photo {
    max-width: 440px;
    height: 570px;
  }
}

@media (min-width: 768px) {
  .card__info-list {
    grid-template-columns: repeat(3, minmax(max-content, 350px));
  }

  .card__wrapper .simpleParallax,
  .card__preview-block {
    margin: 0 -26px 25px;
    height: 320px;
  }

  .card__list_blue li {
    max-width: 450px;
  }

  .card__main-content,
  .about__info *,
  .about__info p,
  .card__list_blue li {
    font-size: 16px;
    line-height: 24px;
  }

  .card__main-content ol {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .card__main-content ul,
  .card__list {
    font-size: 16px;
    margin-bottom: 40px;

    li::before {
      top: 8px;
    }
  }

  .card__author {
    padding-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }

  .card__bottom-content {
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .about {
    .simpleParallax,
    .card__preview-block {
      height: 380px;
    }

    .card__title-block p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .about__title {
    font-size: 36px;
    line-height: normal;
  }

  .card__video {
    height: 420px;
    width: 640px;
  }

  .card__content-wrapper_gray {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    margin-left: -26px;
    margin-right: -26px;
  }

  .card__main-content_gray {
    flex: 0 0 75%;
  }

  .career-info {
    width: 75%;
    margin-left: auto;

    .card__list_blue {
      flex-direction: row;
      gap: 70px;
    }
  }
}

@media (min-width: 1024px) {
  .about {
    .simpleParallax,
    .card__preview-block {
      height: 480px;
    }

    .card__content-wrapper .card__aside-content {
      margin-bottom: 60px;
    }
  }

  .about__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
  }

  .about__photo {
    max-width: 500px;
    flex: 0 0 500px;
    height: 710px;
  }

  .card__wrapper .simpleParallax,
  .card__preview-block {
    height: 420px;
  }

  .career .card__main-content {
    flex-direction: row-reverse;
    align-items: flex-start;
    gap: 20px;
  }

  .person-block span {
    display: none;
  }

  .person-block {
    flex: 0 0 350px;
  }

  .career .card__image {
    flex: 0 0 100%;
    height: 485px;
  }

  .career .card__text p:last-of-type {
    display: inline;
    margin-top: 60px;
  }
}

@media (min-width: 1280px) {
  .card__preview-slide {
    img {
      top: 50%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .card {
    &__container {
      position: relative;

      .breadcrumbs {
        position: absolute;
        top: 9px;
        left: 46px;
        margin-bottom: 0;
        max-width: 35%;
      }
    }
  }

  .card__wrapper {
    display: grid;
    grid-template-areas:
      "b a a"
      "c c c ";
    grid-template-columns: 33% 65%;
    gap: 70px 35px;
  }

  .card__wrapper .simpleParallax,
  .card__preview-block {
    min-width: 100%;
    width: 100%;
    grid-area: a;
    margin: 0;
    height: 570px;

    .card-button-next,
    .card-button-prev {
      display: block;
    }
  }

  .card__title-block {
    grid-area: b;
    padding-top: 200px;
  }

  .card__content-wrapper {
    grid-area: c;
    display: grid;
    grid-template-areas:
      "b a a"
      "b c c";
    grid-template-columns: 32% 62%;
    gap: 60px 35px;
    width: calc(100% - 46px);
  }

  .card__main-content {
    grid-area: a;
    margin-bottom: 0;

    & > * {
      margin-bottom: 30px;
    }
  }

  .news-detail__date {
    font-size: 16px;
    margin-top: 30px;
  }

  .card__aside-content {
    grid-area: b;
    margin-bottom: 0;
  }

  .card__bottom-content {
    grid-area: c;
  }

  .card {
    .title {
      margin-bottom: 30px;
      line-height: 120%;
    }
  }

  .card__link {
    margin-bottom: 40px;
  }

  .card__list_blue {
    max-width: 360px;
  }

  .card__aside-content {
    .card__list_blue {
      gap: 70px;
    }
  }

  .card__info-list li strong {
    gap: 9.5px;
  }

  .card__info-list li span {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .card__info-list li i {
    font-size: 20px;
  }

  .card__info-list li b {
    font-size: 50px;
  }

  .card__info-list li:not(.empty):after {
    width: 46px;
  }

  .card__bottom-content .button,
  .card__title-block .button {
    padding: 16.5px 36px 14.5px;
    font-size: 12px;
    line-height: 110%;
  }

  .card__info-list {
    margin-bottom: 70px;
  }

  .card {
    padding-bottom: 100px;
  }

  .card__list_blue {
    li {
      strong {
        font-size: 24px;
        line-height: 30px;
      }

      b {
        font-size: 16px;
      }
    }
  }

  .card__main-content ol {
    font-size: 18px;
    line-height: 24px;
  }

  .card__main-content ul,
  .card__list {
    font-size: 18px;
    line-height: 24px;

    li::before {
      top: 9px;
    }
  }

  .card__author {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
  }

  .card__list_blue li div a {
    font-size: 18px;
    line-height: 30px;
  }

  .career {
    .card__title-block .button {
      font-size: 12px;
      line-height: 16.5px;
    }
  }

  .card__large-list-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 44px;
  }

  .card__large-list-title {
    font-size: 30px;
    margin-bottom: 28px;
  }

  .card__bottom-content {
    .card__large-list {
      margin-bottom: 0;
      gap: 5px;

      li {
        font-size: 16px;
        line-height: 36.5px;

        &::before {
          top: 15px;
        }
      }
    }

    .contacts__full-list__main-row {
      margin: 40px 0 60px;

      * {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .about {
    .card__content-wrapper {
      display: grid;
    }

    .card__list_blue li {
      padding: 7px 0 7px 16px;

      strong {
        max-width: 170px;
        font-size: 16px;
        line-height: 111%;
      }
    }
  }

  .card__video {
    width: 325px;
    height: 235px;
    margin: 0 0 12px;
  }

  .about__info {
    margin-bottom: 0;
  }

  .about__photo {
    max-width: 380px;
    flex: 0 0 380px;
    height: 550px;
  }

  .about__title {
    font-size: 48px;
  }

  .card__content-wrapper_gray {
    margin-left: -46px;
    margin-right: -98px;
    padding-left: 46px;
    padding-right: 46px;
    padding-top: 90px;
    padding-bottom: 120px;
  }

  .card__main-content_gray {
    & > p {
      font-size: 35px;
      line-height: 50px;
      margin-bottom: 100px;
    }

    .card__info-list {
      gap: 100px 30px;

      li span {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 50px;

        &::after {
          bottom: -20px;
        }
      }
    }
  }

  .card__main-content_gray .card__info-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .career .card__aside-content > p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 60px;
  }

  .career-info .card__list_blue {
    li {
      min-width: fit-content;
    }
  }
}

@media (min-width: 1440px) {
  .card__wrapper {
    grid-template-columns: 35% 63%;
  }

  .card__content-wrapper {
    grid-template-columns: 35% 62%;
  }

  .card__wrapper .simpleParallax,
  .card__preview-block {
    height: 840px !important;
  }

  .card__title-block {
    padding-top: 260px;
  }

  .card__list_blue li {
    font-size: 18px;
    line-height: 30px;
    padding: 10px 0 10px 28px;
  }

  .card__info-list li i {
    font-size: 30px;
  }

  .card__info-list li span {
    font-size: 14px;
  }

  .card__info-list li b {
    font-size: 60px;
  }

  .card__main-content {
    max-width: 1000px;
  }

  .card__list_blue {
    li {
      strong {
        font-size: 30px;
        line-height: 45px;
      }

      b {
        font-size: 18px;
        line-height: 24px;
      }

      div {
        margin-top: 22px;
      }
    }
  }

  .contacts__full-list__main-row {
    * {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .card__bottom-content {
    .contacts__full-list__main-row {
      gap: 90px;

      li {
        flex: 0 0 auto;
      }
    }
  }

  .about {
    .card__title-block {
      padding-top: 160px;
    }

    .card__title {
      max-width: 295px;
    }

    .card__title-block p {
      font-size: 20px;
      line-height: 30px;
      max-width: 365px;
    }

    .card__wrapper .simpleParallax,
    .card__preview-block {
      height: 570px;
    }

    .card__content-wrapper .card__aside-content {
      margin-bottom: 0;
    }

    .about__photo {
      max-width: 470px;
      flex: 0 0 470px;
      height: 600px;
    }
  }

  .career .card__main-content {
    gap: 60px;
  }

  .career .card__main-content {
    max-width: 1150px;
  }

  .card__content-wrapper_gray {
    margin-right: -46px;
  }

  .card__main-content_gray {
    max-width: 1148px;
  }

  .career-info {
    max-width: 1050px;
  }

  .card__main-content_gray .card__info-list {
    gap: 130px 100px;
  }
}

@media (min-width: 1920px) {
  .card {
    .breadcrumbs {
      left: 80px;
    }
  }

  .card__info-list {
    gap: 58px 100px;
  }

  .card__wrapper {
    grid-template-columns: 35% 65.5%;
  }

  .card__content-wrapper {
    grid-template-columns: 34% 62%;
  }

  .card__bottom-content {
    .contacts__full-list__main-row {
      gap: 130px;
    }
  }

  .about .simpleParallax,
  .about .card__preview-block {
    height: 820px;
  }

  .card__video {
    width: 425px;
    height: 286px;
  }

  .about .about__photo {
    max-width: 500px;
    flex: 0 0 500px;
    height: 700px;
    margin: 0;
  }

  .about__wrapper {
    justify-content: flex-start;
    gap: 150px;
  }

  .about__title {
    font-size: 60px;
    line-height: 58px;
    margin-bottom: 40px;
  }

  .card__main-content,
  .about__info *,
  .about__info p {
    font-size: 18px;
    line-height: 30px;
  }

  .card__content-wrapper_gray {
    margin-left: -80px;
    margin-right: -80px;
  }

  .career-info {
    max-width: 1050px;
    width: 100%;
    margin-left: 680px;
  }

  .career-info .card__list_blue {
    gap: 100px;
  }
}

.press-center-section {
    padding-top: 43px;
    padding-bottom: 36px;
}

.press-center-section__title {
    margin-bottom: 15px;
}

.press-center-section__wrapper {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #000000;
    max-width: 1920px;
    margin: 0 auto;
}

.press-center-section__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.press-center-section__item {
    font-family: var(--font-helvetica);
    padding: 16px 5px 20px;
    display: flex;
    gap: 16px;
    border-bottom: 1px solid #000000;
    color: #000000;
    transition: background 0.5s ease;

    &:hover {
        background: #f6f6f6;
    }

    .press-slide__date {
        margin-bottom: 0;
    }
}

.press-center-section__image {
    position: relative;
    flex: 0 0 60px;
    height: 60px;
}

.press-center-section__image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.press-center-section__content {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.press-center-section__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
}

.press-center-section__btn {
    margin: 24px auto 0;
    padding: 11.5px 25px 9.5px;
    text-transform: uppercase;
}

@media (min-width: 768px) {
    .press-center-section__item {
        padding: 20px 10px 25px;
        gap: 20px;
        height: 100%;
    }

    .press-center-section__image {
        flex: 0 0 150px;
        height: 150px;
    }
}

@media (min-width: 1280px) {
    .press-center-section {
        padding-top: 150px;
        padding-bottom: 60px;
    }

    .press-center-section__title {
        margin-bottom: 55px;
    }

    .press-center-section__list {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .press-center-section__wrapper {
        padding-left: 46px;
        padding-right: 46px;
    }

    .press-center-section__btn {
        margin: 64px auto 0;
        padding: 21.5px 33px 19.5px;
        font-size: 12px;
    }

    .press-center-section__list li {
        flex: 0 0 45%;
    }

    .press-center-section__text {
        font-size: 18px;
        line-height: 150%;
    }
}

@media (min-width: 1440px) {
    .press-center-section__item {
        padding: 43px 5px 50px;
    }

    .press-center-section__content {
        gap: 12px;
    }

    .press-center-section__text {
        font-size: 20px;
    }

    .press-center-section__image {
        flex: 0 0 200px;
        height: 200px;
    }
}

@media (min-width: 1920px) {
    .press-center-section__wrapper {
        padding-left: 80px;
        padding-right: 80px;
    }

    .press-center-section__list li {
        flex: 0 0 800px;
    }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 12;
}

.header:not(.header_white) {
  opacity: 0;
  animation: fadeIn ease 0.6s forwards 1.3s;
}

.header_white {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  border-bottom: 0.5px solid var(--color-darker);

  svg {
    color: var(--color-darker);
  }
}

.header__container {
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  display: block;
  position: relative;
  width: 100px;
  flex: 0 0 100px;
  height: 21px;
}

.header__logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.header__logo svg {
  width: 100px;
  height: 21px;
}

.header__actions > .header__link,
.header__actions > .header__search {
  display: none;
}

.header__link {
  svg {
    transition: all 0.18s ease;
  }
}

.header_white .header__actions svg path {
  stroke: var(--color-darker);
}

.header .header__link svg {
  color: transparent;
}

.header_white .search-wrapper.active svg {
  color: #ffffff;
}

.header_white .header__actions > .search-wrapper.active::after {
  z-index: 2;
  background: #ffffff;
}

.header__menu .header__link:hover svg,
.header_white .header__link:hover svg {
  color: var(--color-darker);
}

.header:not(.header_white) {
  .header__logo path {
    fill: white;
  }
}

.header_white {
  .header__logo path {
    fill: var(--color-darker);
  }
}

.header:not(.header_white) .header__actions {
  path {
    stroke: white;
  }

  .header__link:hover svg {
    color: #ffffff;
  }
}

.header_white .header__actions {
  path {
    stroke: var(--color-darker);
  }
}

.header__open-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  flex: 0 0 40px;
  height: 20px;
}

.header__open-btn span {
  display: block;
  position: relative;
  height: 9px;
  width: 100%;
}

.header__open-btn span::before,
.header__open-btn span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  background: #ffffff;
}

.header_white .header__open-btn span::before,
.header_white .header__open-btn span::after {
  background: var(--color-darker);
}

.header__open-btn span::before {
  top: 0;
  width: 100%;
}

.header__open-btn span::after {
  bottom: 0;
  width: 25px;
}

.header__menu {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transform: translate3d(100%, 0, 0);
  will-change: transform;
  transition: transform var(--trans);
  padding: 25px 16px 43px 15px;
  overflow-y: auto;
}

.header__menu.shown {
  transform: translate3d(0, 0, 0);
}

.header__menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12.5px;
  gap: 28px;
}

.header__close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  flex: 0 0 30px;
  height: 30px;
}

.header__close-btn span {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.header__close-btn span::before,
.header__close-btn span::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 1px;
  width: 38px;
  background: #000000;
  transition: all 0.15s ease 0s;
}
.header__close-btn:hover span::before,
.header__close-btn:hover span::after {
  background: #31a8fe;
}

.header__close-btn span::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.header__close-btn span::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.header__menu-body {
  margin-bottom: 21.65px;
}

.header__menu-list .header__menu_external > a {
  position: relative;
}

.header__submenu {
  display: none;
  padding-left: 20px;
  width: 100%;
}

.header__submenu li a {
  font-size: 18px;
}

.header__menu-list li > a {
  display: block;
  color: var(--color-darker);
  font-family: var(--font-helvetica), sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 250%;
  text-transform: uppercase;
}

.header__menu-footer {
  display: flex;
  align-items: center;
  gap: 18px;
}

@media (min-width: 1280px) {
  .container.header__container {
    padding-top: 46px;
    padding-bottom: 46px;
    padding-left: 46px;
    padding-right: 46px;
  }

  .header__logo svg {
    width: 202px;
    height: 41.5px;
  }

  .header_white {
    border-bottom: 1px solid var(--color-darker);
  }

  .header_white .container.header__container {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 29px;
    padding-bottom: 29px;
  }

  .header__logo {
    width: 200px;
    height: 41.5px;
    flex: 0 0 200px;
  }

  .header__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 40px;
  }

  .header__menu-header {
    justify-content: flex-end;
  }

  .header__close-btn {
    margin-right: 35px;
  }

  .header__actions > .header__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }

  .header__actions > .header__search {
    display: flex;
  }

  .header__actions > .search-wrapper::after {
    background: #ffffff;
  }

  .header__actions > .search-wrapper {
    transition: all 0.15s ease;
  }

  .header_white .header__actions > .search-wrapper::after {
    background: var(--color-darker);
  }

  .header__actions > .search-wrapper input {
    color: #ffffff;
    transition: all 0.15s ease;
  }

  .header__actions > .search-wrapper input::placeholder {
    color: #dbdbdb !important;
  }

  .header__menu-footer,
  .header__menu-header .search-wrapper {
    display: none;
  }

  .header__menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 100%;
  }

  .header__menu-header {
    position: relative;
    width: 575px;
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .header__menu-body {
    position: relative;
    width: 575px;
    padding-left: 70px;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .header__menu_external > li a::after {
    display: none;
  }

  .header__menu-list li > a {
    position: relative;
    width: fit-content;
    transition: all var(--trans);
  }

  .header__menu-list li > a:hover,
  .header__menu-list li > a.active {
    color: #31a8fe;
  }

  .header__menu-list li > a::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 0;
    bottom: 10px;
    height: 1px;
    background: #31a8fe;
    transition: all var(--trans);
  }

  .header__submenu li > a::before {
    height: 2px;
    bottom: 0;
  }

  .header__menu-list li > a:hover::before {
    max-width: 100%;
  }

  .header__menu-wrapper {
    position: relative;
  }

  .header__submenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    top: 0;
    left: 0;
    min-height: 800px;
    height: 100vh;
    position: fixed;
    padding-left: 60px;
    width: calc(100vw - 590px);
    transition: opacity var(--trans);
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);

    li a {
      line-height: 125%;
    }
  }

  .header__submenu li > a {
    font-size: 40px;
  }

  .header__menu {
    height: 100vh;
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .header__close-btn {
    flex: 0 0 65px;
    width: 65px;
    height: 65px;
    margin-right: 18px;
  }

  .header__close-btn span::before,
  .header__close-btn span::after {
    width: 68px;
  }
}

@media (min-width: 1920px) {
  .header_white .container.header__container,
  .container.header__container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .header__submenu {
    width: calc(100vw - 608px);
    padding-left: 80px;
  }

  .header__submenu li > a {
    font-size: 50px;
  }

  .header__close-btn {
    margin-right: 54px;
  }
}

@media (min-width: 2560px) {
  .header__close-btn {
    margin-right: 370px;
  }
}

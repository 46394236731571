@keyframes ld-spin {
  0% {
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid var(--color-darker);
  border-radius: 50%;
  transition: all var(--trans);

  &_white {
    border: 1px solid #ffffff;

    svg path {
      stroke: #ffffff;
    }
  }
}

.button-circle:hover {
  background: var(--color-dark);
}

.button-circle_white:hover {
  background: #ffffff;
}

.button-circle_white:hover svg path {
  stroke: var(--color-dark) !important;
}

.button-circle:hover svg path {
  stroke: #ffffff;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  color: #000000;
  font-family: var(--font-helvetica);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  padding: 11.5px 25.5px 9.5px;
  border-radius: 100px;
  background: transparent;
  border: 1px solid #000000;
  transition: all var(--trans);
  text-align: center;
  width: fit-content;
  text-transform: uppercase;

  &:hover {
    background: var(--color-dark);
    color: #ffffff;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &::before {
    position: absolute;
    content: "";
    opacity: 0;
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    transition-timing-function: ease-in;
    background: rgba(240, 240, 240, 0.8);
  }

  span {
    display: block;
    position: relative;
    transition: all var(--trans);

    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 3px solid currentColor;
      border-left-color: transparent;
      opacity: 0;
      transition: all 0.2s;
      right: 0;
      top: -3px;
    }
  }

  &.loading {
    overflow: hidden;
    position: relative;

    &::before {
      opacity: 1;
    }

    span {
      padding-right: 35px;

      &::after {
        opacity: 0.6;
        animation: ld-spin 1s infinite linear;
      }
    }
  }
}

.button_dark {
  background: var(--color-dark);
  color: #ffffff;
  border: 1px solid #ffffff;
}

.button_dark:hover {
  background: #ffffff;
  color: #000000;
}

.card-button-next,
.card-button-prev {
  cursor: pointer;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1) url("../img/icons/card-arrow.svg") center
    no-repeat;
  background-size: 15px 30px;
  z-index: 1;

  &.swiper-button-lock {
    display: none !important;
  }
}

.card-button-next {
  right: 75px;
  top: 50%;
  transform: translateY(-50%);
}

.card-button-prev {
  left: 75px;
  top: 50%;
  transform: rotate(180deg) translateY(50%);
}

.button-back {
  display: flex;
  align-items: center;
  gap: 6.5px;
  padding: 0 23px !important;
  height: 40px;
}

.button-back:hover svg path {
  stroke: #ffffff;
}

@media (min-width: 1280px) {
  .button-circle {
    width: 110px;
    height: 110px;
  }

  .button {
    padding: 21.5px 27px 19.5px;

    &.loading span::after {
      top: -4px;
    }
  }
}

@media (min-width: 1440px) {
  .button-circle {
    width: 150px;
    height: 150px;
  }

  .card-button-next {
    right: 85px;
  }

  .card-button-prev {
    left: 85px;
  }

  .card-button-next,
  .card-button-prev {
    width: 80px;
    height: 80px;
  }

  .button-back {
    padding: 0 36px !important;
    height: 50px;
  }
}

@media (min-width: 1920px) {
  .card-button-next {
    right: 105px;
  }

  .card-button-prev {
    left: 105px;
  }
}

.form-field {
  padding-bottom: 10px;
  border-bottom: 1px solid #333333;
  color: #333333;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;

  input,
  textarea {
    min-width: 100%;
    color: #333333;
    font-size: inherit;
  }

  &__date {
    max-width: 180px;
    input {
      min-width: 180px;
      max-width: 180px;
    }
  }

  input[type="date"]:focus::-webkit-datetime-edit-year-field,
  input[type="date"]:focus::-webkit-datetime-edit-month-field,
  input[type="date"]:focus::-webkit-datetime-edit-day-field,
  input[type="date"]:focus::-webkit-datetime-edit {
    color: #333333 !important;
  }

  input[type="date"]:in-range::-webkit-datetime-edit-year-field,
  input[type="date"]:in-range::-webkit-datetime-edit-month-field,
  input[type="date"]:in-range::-webkit-datetime-edit-day-field,
  input[type="date"]:in-range::-webkit-datetime-edit {
    color: transparent;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill,
  input:-moz-autofill,
  textarea:-moz-autofill,
  select:-moz-autofill {
    background: transparent;
  }

  select {
    cursor: pointer;
    font-size: inherit;
    color: #333333;
    width: 100%;
    max-width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url("../img/icons/select-arrow.svg") right no-repeat;
    background-size: 15px 7.5px;
  }
}

.err-message {
  color: tomato;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-file {
  position: relative;
  display: flex;
  align-items: center;
  gap: 18.5px;

  input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }
}

.form-file-btn {
  cursor: pointer;
  padding: 6.5px 21.5px 8.5px;
  text-transform: none;
}

.form-file-text {
  color: #333333;
  font-family: var(--font-helvetica);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
}

.form-checkbox {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;

  &:hover span:not(.checkbox) {
    color: var(--color-blue);
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;

    &:checked + .checkbox {
      background-size: 75%;
    }
  }

  span:not(.checkbox) {
    color: var(--color-darker);
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    transition: all var(--trans);
  }

  .checkbox {
    cursor: pointer;
    display: block;
    width: 20px;
    flex: 0 0 20px;
    height: 20px;
    border: 1px solid var(--color-darker);
    background: url("../img/icons/check-icon.svg") center no-repeat;
    background-size: 0;
    transition: all 0.15s ease;
  }
}

@media (min-width: 1024px) {
  .form-field {
    padding-bottom: 20px;
    font-size: 16px;

    select {
      background-size: 25px 12.5px;
    }
  }

  .form-file-text {
    font-size: 16px;
  }
}

.form-field textarea {
  resize: none;
}

.form-field span {
  position: absolute;
  pointer-events: none;
  transition: opacity var(--trans);
}

.form-field:has(input:focus) span,
.form-field.active span {
  opacity: 0;
}

.form-field:has(textarea:focus) span,
.form-field.active span {
  opacity: 0;
}

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 1024px) {
  .form-field-wrapper {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    align-items: center;
    gap: 20px;
  }
}

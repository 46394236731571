@-webkit-keyframes loop {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
    }
}

@-moz-keyframes loop {
    0% {
        -moz-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    100% {
        -moz-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
    }
}

@keyframes loop {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.footer {
    address {
        font-style: normal;
    }
}

.footer__carousel {
    color: var(--color-darker);
    font-family: var(--font-helvetica);
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 13px;
    padding-bottom: 14px;
    border-top: 1px solid var(--color-darker);
    border-bottom: 1px solid var(--color-darker);
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}

.footer__carousel p {
    padding-left: 100%;
    display: inline-block;
    width: fit-content;
    animation: loop 110s linear infinite;
    -webkit-animation: loop 110s linear infinite;
}

.footer__wrapper {
    padding-top: 20px;
    padding-bottom: 50px;
    font-family: var(--font-helvetica);
}

.footer__logo {
    display: none;
}

.footer__info-list * {
    color: var(--color-darker);
    text-align: center;
    font-family: var(--font-helvetica);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
}

.footer__nav {
    display: none;
}

.footer__links {
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}

.footer__row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 14px;
}

.footer__row a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    flex: 0 0 50px;
    border: 1px solid var(--color-darker);
    border-radius: 50%;
    transition: all var(--trans);
}

.footer__row a svg {
    transition: all var(--trans);
}

.footer__row a:hover {
    background: var(--color-darker);
}

.footer__row a:hover svg path {
    fill: #ffffff;
}

.footer__copyright {
    color: #222;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
}

@media (min-width: 1280px) {
    .footer__carousel {
        font-size: 60px;
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .footer__wrapper {
        padding-top: 70px;
        padding-bottom: 65px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .footer__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        flex: 0 0 200px;
        height: 41.5px;
    }

    .footer__info-list {
        flex: 0 0 250px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .footer__info-list * {
        text-align: left;
    }

    .footer__nav {
        display: flex;
        gap: 84px;
    }

    .footer__nav * {
        color: var(--color-darker);
        font-family: var(--font-helvetica);
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
    }

    .footer__info-list a,
    .footer__nav a {
        display: block;
        position: relative;
        width: fit-content;
        transition: all var(--trans);
    }

    .footer__info-list a:hover,
    .footer__nav a:hover {
        color: #31a8fe;
    }

    .footer__info-list a::before,
    .footer__nav a::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 6px;
        height: 1px;
        background: #31a8fe;
        opacity: 0;
        transition: opacity var(--trans);
    }

    .footer__info-list a::before {
        bottom: 2px;
    }

    .footer__info-list a:hover::before,
    .footer__nav a:hover::before {
        opacity: 1;
    }

    .footer__links {
        align-items: flex-end;
    }
}

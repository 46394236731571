.development__container {
  padding-top: 43px;
  counter-reset: item;
}

.development__title {
  padding-bottom: 15px;
}

.development__wrapper {
  padding-top: 37px;
  border-top: 0.5px solid #000000;
}

.development__list-subtitle {
  color: #222222;
  font-family: var(--font-helvetica);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 16px;
  padding-left: 15px;
  max-width: 150px;
}

.development__list {
  border-top: 0.5px solid #000000;
}

.development__list li {
  position: relative;
  border-bottom: 0.5px solid #000000;

  &:hover:before {
    color: #ffffff;
    z-index: 21;
  }
}

.development__item {
  position: relative;
  display: flex;
  gap: 7.5px;
  font-family: var(--font-helvetica);
  padding: 15px 16px 22px 35px;
  transition: all var(--trans);
}

.development__item:hover {
  background: var(--color-dark);

  .development__item-text {
    color: #ffffff;
  }
}

.development__list li:before {
  position: absolute;
  content: "";
  font-family: var(--font-helvetica);
  color: #999999;
  font-size: 10px;
  font-weight: 300;
  line-height: normal;
  top: 18px;
  left: 16px;
  counter-increment: item;
  content: counter(item, decimal-leading-zero) "";
}

.development__item .development__item-text {
  color: #222222;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
}

.other-directions {
  background: var(--color-dark);
  padding-top: 30px;
  padding-bottom: 68px;
}

.other-directions__header {
  margin-bottom: 24px;
}

.other-directions__title {
  color: #ffffff;
}

.other-directions__header .slider-button-prev_thin,
.other-directions__header .slider-button-next_thin {
  display: none;
}

.other-directions__slider {
  height: 300px;
  margin-right: -16px;
}

.other-directions__slide img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  transition: all 1s ease;
}

.other-directions__slide::after {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(62, 76, 115, 0.5);
  transition: all 1s ease;
  z-index: -1;
}

.other-directions__slide:hover img {
  width: 110%;
  height: 110%;
}

.other-directions__slide:hover::after {
  background: transparent;
}

.other-directions__slide {
  position: relative;
  height: 300px;
  padding: 24px 16px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}

.other-directions__slide__title {
  color: #ffffff;
  font-family: var(--font-helvetica);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  word-break: break-word;
}

@media (min-width: 768px) {
  .development__list li:before {
    font-size: 12px;
  }

  .development__item .development__item-text {
    font-size: 24px;
  }

  .development__list-subtitle {
    font-size: 20px;
    max-width: 200px;
    padding-left: 26px;
  }

  .development__item {
    padding: 15px 26px 22px 40px;
  }

  .other-directions__slide,
  .other-directions__slider {
    height: 400px;
  }

  .other-directions__slider {
    margin-right: -26px;
  }
}

@media (min-width: 1280px) {
  .development__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid var(--color-darker);
    padding-top: 0;
    padding-left: 40px;
    padding-right: 40px;
  }

  .development__container {
    padding-top: 150px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .development__container .breadcrumbs {
    margin-bottom: 113px;
  }

  .development__list {
    flex: 0 0 710px;
    border-top: none;
  }

  .development__list-subtitle {
    padding-left: 0;
    padding-top: 24px;
  }

  .development__title {
    padding-bottom: 56px;
  }

  .development__item {
    padding: 24px 13px 25px 56px;
    gap: 27px;
  }

  .development .development__wrapper:not(:last-of-type) li {
    border-bottom: 1px solid var(--color-darker);
  }

  .development .development__wrapper:not(:last-of-type) li:last-of-type {
    border-bottom: none;
  }

  .development__list li:before {
    font-size: 14px;
    top: 35px;
    left: 13px;
  }

  .development__item .development__item-text {
    font-size: 50px;
  }

  .other-directions {
    margin-top: 150px;
  }

  .hover-reveal {
    position: absolute;
    z-index: -1;
    width: 350px;
    height: 200px;
    top: 50%;
    left: 25px;
    pointer-events: none;
    opacity: 0;
    will-change: transform, filter;
  }

  .hover-reveal__inner {
    overflow: hidden;
  }

  .hover-reveal__inner,
  .hover-reveal__img {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .hover-reveal__img {
    background-size: cover;
    background-position: 50% 50%;
  }

  .other-directions__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 60px;
  }

  .other-directions__nav {
    display: flex;
    align-items: center;
    gap: 48px;
  }

  .other-directions__header .slider-button-prev_thin,
  .other-directions__header .slider-button-next_thin {
    display: flex;
    background: none;
    flex: 0 0 40px;
    width: 40px;
    height: 29px;
    transform: none;
  }

  .other-directions__slider {
    margin-right: -46px;
  }

  .other-directions__slide__title {
    font-size: 26px;
  }

  .other-directions {
    padding-top: 98px;
    padding-bottom: 110px;
  }
}

@media (min-width: 1440px) {
  .development__list {
    flex: 0 0 850px;
  }

  .hover-reveal {
    width: 400px;
    height: 250px;
  }

  .other-directions__header .slider-button-prev_thin,
  .other-directions__header .slider-button-next_thin {
    flex: 0 0 49px;
    width: 49px;
    height: 38px;
  }

  .other-directions__slide,
  .other-directions__slider {
    height: 500px;
  }

  .other-directions__slide {
    padding: 35px 20px;
  }

  .other-directions__slide__title {
    font-size: 32px;
  }
}

@media (min-width: 1920px) {
  .development__wrapper,
  .development__container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .development__list {
    flex: 0 0 1165px;
  }

  .hover-reveal {
    width: 500px;
    height: 330px;
  }

  .other-directions__slide {
    padding: 69px 40px;
  }

  .other-directions__slide__title {
    font-size: 40px;
  }

  .other-directions__slide,
  .other-directions__slider {
    height: 600px;
  }
}

@media (min-width: 2560px) {
  .development__wrapper {
    padding-left: 380px;
    padding-right: 0;
  }

  .development__list {
    flex: 0 0 1545px;
}
}

@media (max-width: 1279px) {
  .hover-reveal {
    display: none !important;
  }
}
